import React from 'react';

import { useTranslation } from "react-i18next"

const Map = () => {
    const { t } = useTranslation();
    return (
        <div>
            <iframe width="100%" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=9.529923498630525%2C42.37924447520594%2C9.532927572727203%2C42.38159232705318&amp;layer=mapnik"></iframe><br /><small><a href="https://www.openstreetmap.org/#map=19/42.38042/9.53143" target="_blank">{t("__Afficher la carte en plein écran")}</a></small>
        </div>
    )
}

export default Map;